import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import darkTheme from '../darkTheme';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { ReactComponent as BrokenWorkIcon } from '../Icons/BrokenWorkIcon.svg';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popoverPaper: {
            marginLeft: '-5%',
            paddingLeft: '5%',
            paddingRight: '5%',
            width: '120vw !important',
            height: 'auto',
            maxHeight: 'unset',
        },
        menuItem: {
            textDecoration: 'inherit',
            color: darkTheme.palette.text.secondary,
        },
        menuButtonIcon: {
            color: theme.palette.text.secondary,
        },
        headers: {
            color: theme.palette.text.secondary,
            fontSize: '12px',
            display: 'none',

            [theme.breakpoints.up('md')]: {
                display: 'inline-flex',
            },
        },
        icons: {
            width: 17,
            height: 16,
            paddingRight: 5,

            [theme.breakpoints.down('sm')]: {
                paddingTop: 5,
            },
        },
        checkIcon: {
            width: 16,
            height: 15,
            paddingRight: 5,
            color: '#BCAG67',
            backgroundColor: '#000',
            [theme.breakpoints.down('sm')]: {
                paddingTop: 5,
            },
        },
        link: {
            textDecoration: 'none',
            color: 'inherit',
        },
        desktopMode: {
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
    }),
);

/**
 * Hamburgerbar navbar button which when clicked expands a navigation menu to the projects- or machines page
 */
const NavbarBtn: FC = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openMenu, setOpenMenu] = React.useState<boolean>(false);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log('currect target', event.currentTarget);
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenu(false);
    };

    return (
        <div className={classes.desktopMode}>
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                aria-controls="customized-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                {openMenu ? (
                    <ClearOutlinedIcon className={classes.menuButtonIcon} />
                ) : (
                    <MenuIcon className={classes.menuButtonIcon} />
                )}
            </IconButton>
            <Menu
                PopoverClasses={{ paper: classes.popoverPaper }}
                id="customized-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                getContentAnchorEl={null}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.desktopMode}
            >
                <MenuItem onClick={handleClose}>
                    <Link className={classes.link} to={`/prosjekter`} replace>
                        <div className={classes.menuItem}>
                            <WorkOutlineIcon className={classes.icons} />
                            Aktive Prosjekter
                        </div>
                    </Link>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <Link className={classes.link} to={`/prosjekter`} replace>
                        <div className={classes.menuItem}>
                            <ListItemText>
                                <BrokenWorkIcon className={classes.checkIcon} />
                                Fullførte Prosjekter
                            </ListItemText>
                        </div>
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link className={classes.link} to={`/maskiner`} replace>
                        <div className={classes.menuItem}>
                            <LocalShippingIcon className={classes.icons} />
                            Maskiner
                        </div>
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link className={classes.link} to={`/profil`} replace>
                        <div className={classes.menuItem}>
                            <PersonOutlineIcon className={classes.icons} />
                            Profil
                        </div>
                    </Link>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default NavbarBtn;
