export const user_info = [
    {
        id: 1,
        first_name: 'Jacob',
        last_name: 'Entreprenør',
        company: 'Kvist AS',
        phone_nr: '+47 97384111',
        email: 'jacob.en@kvist.no',
        city: 'Oslo',
        post_nr: '0572',
        address: 'Kvistveien 16B',
        green_boxes: ['00234', '00123', '00049', '00394', '00049', '00883', '01123', '00234'],
    },
];
