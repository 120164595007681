import React, { FC } from 'react';
import { IMachine } from '../interfaces/machine';
import { IMachineProd } from '../interfaces/machine_prod';
import { machines } from '../mock_data/machine_info';
import { all_data } from '../mock_data/object_prod_info';
import { IOrder } from '../models/order.interface';
import MachineAccordionDetails from './MachineAccordionDetails';

interface Props {
    /** General information about an order */
    order: IOrder;
}

/**
 * Container for the machine accordion component used in the project page, outputting an accordion for each machine currently used in an order on the project page
 */
const MachinesAccordion: FC<Props> = (props: Props) => {
    const { order } = props;

    const projectDetails = all_data.filter(function (projectData: any) {
        return projectData.project_id.toString() === order.orderId;
    });

    return (
        <>
            {projectDetails.map((projectDet: IMachineProd, key: number) => {
                return (
                    <MachineAccordionDetails
                        key={key}
                        projectDetails={projectDet}
                        machineDetails={projectDet.machine_vals}
                        machine={
                            machines.filter(function (machine: IMachine) {
                                return machine.machine_id === projectDet.machine_id;
                            })[0]
                        }
                    />
                );
            })}
        </>
    );
};

export default MachinesAccordion;
