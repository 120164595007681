import { createTheme, unstable_createMuiStrictModeTheme } from '@material-ui/core';
import { Shadows } from '@material-ui/core/styles/shadows';

// findDOMNode warning dev
const createCustomTheme = process.env.NODE_ENV === 'production' ? createTheme : unstable_createMuiStrictModeTheme;

const darkTheme = createCustomTheme({
    palette: {
        type: 'dark',
        common: {
            black: '#000',
            white: '#FFFFFF',
        },
        primary: {
            main: '#031401', // Primær bakgrunn
            dark: '#020F00', // Primær mørk
            contrastText: '#C2DBBF', // Primær 5
        },
        secondary: {
            main: '#BD9672', // Sekundær 1
            dark: '#5E8359', // Primær ikoner mørk
        },
        error: {
            main: '#AF1818', // Avvik rød
        },
        warning: {
            main: '#D65B40', // Signaloransj
        },
        text: {
            primary: '#86AA80', // Primær skrift & ikoner
            secondary: '#ECF7DD', // Lys skrift
        },
        background: {
            default: '#283430', // Grågrønn
            paper: '#020F00', // Mørk bak graf
        },
        divider: '#3B5A35', // Primær tynne streker,
        info: {
            light: '#F5B22F', // Fremkjørt
            main: '#D36666', // Hugget
            dark: '#62B2CC', // Fraktet
            contrastText: '#F1F1F1', // Hvit bar
        },
    },
    shadows: Array(25).fill('none') as Shadows,
    typography: {
        fontFamily: 'Poppins',
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightBold: 700,
        h1: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: '15px',
        },
        h2: {
            fontFamily: 'Poppins',
            fontWeight: 800,
            fontSize: '22px',
        },
        h3: {
            fontFamily: 'Poppins',
            fontWeight: 700,
            fontSize: '18px',
        },
        h4: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            fontSize: '15px',
        },
        h5: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            fontSize: '13px',
        },
        h6: {
            fontFamily: 'Poppins',
            fontWeight: 300,
            fontSize: '13px',
        },

        subtitle1: {
            fontFamily: 'Poppins',
            fontWeight: 700,
            fontSize: '16px',
        },
        subtitle2: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '40px',
        },
        body1: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: '18px',
        },
        body2: {
            fontFamily: 'Poppins',
            fontWeight: 700,
            fontSize: '14px',
        },
        overline: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '45px',
        },
        caption: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '16px',
        },
        button: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            fontSize: '16px',
        },
    },
});

export default darkTheme;
