import { Theme, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

const Accordion = withStyles((theme: Theme) => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.up('md')]: {
            borderLeft: `1px solid ${theme.palette.divider}`,
            borderRight: `1px solid ${theme.palette.divider}`,
        },
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
            borderBottom: 0,
        },
    },
    expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        borderBottom: `1px solid ${theme.palette.divider}`,
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
            borderBottom: 0,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
            borderBottom: 0,
        },
    },
    expanded: {
        borderBottom: 0,
    },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: 0,
        backgroundColor: theme.palette.primary.main,
    },
}))(MuiAccordionDetails);

export { Accordion, AccordionSummary, AccordionDetails };
