import React from 'react';
import { Typography, makeStyles, Theme } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import { IDailyReports } from '../interfaces/IDailyReport';
import { IOrder } from '../models/order.interface';
import darkTheme from '../darkTheme';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    title: {
        color: theme.palette.text.secondary,
        fontSize: 18,
    },
    volumeBox: {
        paddingTop: 20,
        paddingLeft: 20,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    volumeBox2: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 140,
            flexWrap: 'wrap',
        },
    },
    hogdvolume: {
        color: theme.palette.info.main,
    },
    framkjortVolume: {
        color: theme.palette.info.light,
    },
    desktopHeader: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'inline-flex',
            alignContent: 'right',
            flexWrap: 'wrap',
        },
    },
    headerTitle: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'inline-flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
    },
}));

interface Props {
    /** Mock data consisting of values and dates for populating the chart */
    report: IDailyReports[];

    /** General information about an order */
    order: IOrder;

    /** The width of the chart */
    width: number;

    /** The height of the chart */
    height: number;
}

/**
 * Chart displaying day-to-day production and driven products
 */
const LineChart = (props: Props): any => {
    const { order } = props;

    const classes = useStyles();

    const testdate = props.report
        .filter((rep) => rep.id === order.orderNum)
        .map((report) => {
            return report.date;
        });

    const testHogdVolume = props.report
        .filter((rep) => rep.id === order.orderNum)
        .map((report) => {
            return report.trees_chopped;
        });

    const testDrivenVolume = props.report
        .filter((rep) => rep.id === order.orderNum)
        .map((report) => {
            return report.driven;
        });

    return (
        <>
            <div>
                <div className={classes.headerTitle}>
                    <Typography variant="h3" color="textSecondary">
                        Dagsproduksjon
                    </Typography>
                    <div style={{ paddingLeft: 205 }}>
                        <Typography variant="h5" className={classes.hogdvolume}>
                            ― Hogd volum
                        </Typography>
                        <Typography variant="h5" className={classes.framkjortVolume}>
                            ― Framkjørt Volum
                        </Typography>
                    </div>
                </div>
                <Line
                    data={{
                        labels: testdate[0].map((date, index) => {
                            const d = new Date(date);
                            const today = new Date();

                            if (d.setHours(0, 0, 0) <= today.setHours(0, 0, 0)) {
                                if (index % 2 !== 0) {
                                    if (d.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
                                        return 'Idag';
                                    } else {
                                        return '';
                                    }
                                } else {
                                    if (d.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
                                        return 'Idag';
                                    } else {
                                        return d.getDate().toLocaleString() + '.' + (d.getMonth() + 1);
                                    }
                                }
                            }
                            return null;
                        }),
                        datasets: [
                            {
                                label: 'Trees chopped',
                                data: testHogdVolume[0].map((volum) => {
                                    return volum;
                                }),
                                fill: false,
                                borderColor: darkTheme.palette.info.main,
                                tension: 0.1,
                                pointBackgroundColor: darkTheme.palette.info.main,
                            },
                            {
                                label: 'Trees driven',
                                data: testDrivenVolume[0].map((volum) => {
                                    return volum;
                                }),
                                fill: false,
                                borderColor: darkTheme.palette.info.light,
                                tension: 0.1,
                                pointBackgroundColor: darkTheme.palette.info.light,
                            },
                        ],
                        strokeColor: 'rgba(220,220,220,1)',
                    }}
                    options={{
                        showXLabels: 5,
                        maintainAspectRatio: true,
                        responsive: true,
                        elements: {
                            point: {
                                pointBorderWidth: 3,
                                pointRadius: 4,
                                pointBorderColor: '#000',
                            },
                            line: {
                                borderWidth: 1,
                            },
                        },
                        plugins: {
                            legend: {
                                align: 'start',
                                position: 'bottom',
                                display: false,
                                usePointStyles: true,
                            },
                            tooltip: {
                                enabled: false,
                                display: false,
                                borderWidth: 0,
                                cornerRadius: 10,
                            },
                        },
                        scales: {
                            x: {
                                grid: {
                                    display: true,
                                    lineWidth: 0.5,
                                    color: darkTheme.palette.divider,
                                    width: 100,
                                    tickColor: darkTheme.palette.primary.main,
                                },

                                ticks: {
                                    font: {
                                        size: 13,
                                        family: 'poppins',
                                    },
                                    align: 'center',
                                    color: darkTheme.palette.primary.contrastText,
                                },
                            },
                            y: {
                                grid: {
                                    display: true,
                                    lineWidth: 0.5,
                                    color: darkTheme.palette.divider,
                                    tickColor: darkTheme.palette.primary.main,
                                },
                                suggestedMin: 0,
                                ticks: {
                                    font: {
                                        size: 13,
                                        family: 'poppins',
                                    },
                                    autoSkip: true,
                                    maxTicksLimit: 5,
                                    color: darkTheme.palette.primary.contrastText,
                                },
                            },
                        },
                    }}
                    width={500}
                    height={280}
                />
            </div>
        </>
    );
};

export default LineChart;
