export const machines = [
    {
        machine_id: 'abc',
        machine_type: 'John Deere 1270 E',
        prod_type: 'hogstmaskin',
        feltGIS: '00234',
        machine_vals: {
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Total timer i drift': 100,
        },
    },
    {
        machine_id: 'def',
        machine_type: 'Komatsu Forest 855',
        prod_type: 'lassebærer',
        feltGIS: '00123',
        machine_vals: {
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Total timer i drift': 100,
        },
    },
    {
        machine_id: 'asd',
        machine_type: 'Eco Log Sweden AB 2800 E',
        prod_type: 'hogstmaskin',
        feltGIS: '00049',
        machine_vals: {
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Total timer i drift': 100,
        },
    },
    {
        machine_id: '3mj',
        machine_type: 'Eco Log Sweden AB 122 C',
        prod_type: 'lassebærer',
        feltGIS: '00394',
        machine_vals: {
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Total timer i drift': 100,
        },
    },
    {
        machine_id: '3hj',
        machine_type: 'Eco Log Sweden AB 2800 E',
        prod_type: 'hogstmaskin',
        feltGIS: '00049',
        machine_vals: {
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Total timer i drift': 100,
        },
    },
    {
        machine_id: '0dj',
        machine_type: 'Komatsu Forest 855',
        prod_type: 'lassebærer',
        feltGIS: '00883',
        machine_vals: {
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Total timer i drift': 100,
        },
    },
    {
        machine_id: '3kj',
        machine_type: 'Komatsu Forest 855',
        prod_type: 'hogstmaskin',
        feltGIS: '01123',
        machine_vals: {
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Total timer i drift': 100,
        },
    },
    {
        machine_id: 'sjh',
        machine_type: 'John Deere 1132 E',
        prod_type: 'lassebærer',
        feltGIS: '00234',
        machine_vals: {
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Total timer i drift': 100,
        },
    },
];
