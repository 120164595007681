import IUser from '../interfaces/user';
import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { IMachine } from '../interfaces/machine';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import desktopWelcomeImg from '../images/artwork.png';

const useStyles = makeStyles((theme) => ({
    titleFlexBox: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderBottom: `0.01px solid ${theme.palette.divider}`,
        padding: '4%',
        paddingTop: '20%',
        [theme.breakpoints.up('md')]: {
            paddingTop: '0%',
        },
    },
    wrapperFlexBox: {
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingBottom: '40%',
        [theme.breakpoints.up('md')]: {
            paddingBottom: '0%',
        },
    },
    infoFlexBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '4%',
        borderBottom: `0.01px solid ${theme.palette.divider}`,
        [theme.breakpoints.up('md')]: {
            borderTop: `0.01px solid ${theme.palette.divider}`,
            borderLeft: `0.01px solid ${theme.palette.divider}`,
            borderRight: `0.01px solid ${theme.palette.divider}`,
        },
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.palette.text.primary,
    },
    addBoxIcon: {
        fontSize: 24,
    },
    profileBox: {
        [theme.breakpoints.up('md')]: {
            backgroundColor: theme.palette.primary.main,

            width: 700,
            height: 'auto',
            margin: '0 auto',
            padding: '60px',
            position: 'absolute',
            borderRadius: 20,
            marginTop: 350,

            alignSelf: 'center',
            overflow: 'auto',
        },
    },
    greenBox: {
        [theme.breakpoints.up('md')]: {
            backgroundColor: theme.palette.primary.main,
            display: 'block',
            width: 700,
            minHeight: 700,
            padding: '60px',
            position: 'relative',
            borderRadius: 20,
            marginTop: 550,
            alignSelf: 'center',
            overflowY: 'hidden',
            marginBottom: 200,
        },
    },
    profilePageDesktop: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundImage: `url(${desktopWelcomeImg})`,
            height: '100vh',
            overflow: 'auto',
            position: 'sticky',
        },
    },
    desktopTitle: {
        [theme.breakpoints.up('md')]: {
            fontSize: '96px',
            fontWeight: 500,
        },
    },
    titleFlexBoxMain: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',

            marginLeft: 161,
            marginTop: 200,
        },
    },
}));

interface Props {
    /** Mock data consisting of information about the current user of the application */
    user: IUser;

    /** Mock data consisting of a list of machines, which furthermore contain general information about the machine */
    machines: IMachine[];
}

/**
 * Profile page component displaying general information about the current user in addition to the entrepreneur's green boxes
 */
const ProfileComponent: FC<Props> = ({ user, machines }: Props) => {
    const classes = useStyles();

    const green_boxes = user.green_boxes;

    const getMachine = (feltGIS: string): string => {
        return machines.filter(function (machine: IMachine) {
            return machine.feltGIS === feltGIS;
        })[0].machine_type;
    };

    return (
        <div className={classes.profilePageDesktop}>
            <div className={classes.titleFlexBoxMain}>
                <Typography className={classes.desktopTitle} color="textSecondary" variant="h2">
                    Din Profil
                </Typography>
            </div>
            <div id="profil" className={classes.profileBox}>
                <div className={classes.titleFlexBox}>
                    <Typography color="textSecondary" variant="h2">
                        Profil
                    </Typography>
                    <Typography variant="subtitle1" color="textPrimary">
                        {`${user.first_name} ${user.last_name}`} - {user.company}
                    </Typography>
                </div>

                <div className={classes.infoFlexBox}>
                    <Typography color="textSecondary" variant="h1">
                        Mobil
                    </Typography>
                    <Typography variant="h4">{user.phone_nr}</Typography>
                </div>
                <div className={classes.infoFlexBox}>
                    <Typography color="textSecondary" variant="h1">
                        Email
                    </Typography>
                    <Typography variant="h4">{user.email}</Typography>
                </div>
                <div className={classes.infoFlexBox}>
                    <Typography color="textSecondary" variant="h1">
                        Adresse
                    </Typography>
                    <Typography variant="h4">{`${user.address}, ${user.post_nr} ${user.city}`}</Typography>
                </div>
            </div>

            <div id="bokser" className={classes.greenBox}>
                <div className={classes.titleFlexBox}>
                    <Typography color="textSecondary" variant="h2">
                        Grønne bokser
                    </Typography>
                </div>

                <div className={classes.wrapperFlexBox}>
                    {green_boxes.map((box: string, key: number) => {
                        return (
                            <div className={classes.infoFlexBox} key={key}>
                                <Typography color="textSecondary" variant="h1">{`FeltGIS: ${box}`}</Typography>
                                <Typography variant="h4">{getMachine(box)}</Typography>
                            </div>
                        );
                    })}
                    <div className={classes.infoFlexBox}>
                        <div className={classes.button}>
                            <AddBoxOutlinedIcon className={classes.addBoxIcon} />
                            <Typography variant="h1">Legg til ny</Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileComponent;
