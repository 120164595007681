import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { FC } from 'react';
import { IMachine } from '../interfaces/machine';
import MachineAccordionDetails from './MachineAccordionDetails';
import desktopWelcomeImg from '../images/artwork.png';

const useStyles = makeStyles((theme) => ({
    titleFlexBox: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: '4%',
        paddingTop: '20%',
        [theme.breakpoints.up('md')]: {},
    },
    titleFlexBoxMain: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            fontSize: 96,
            marginLeft: 150,
            marginTop: 200,
            color: theme.palette.primary.contrastText,
        },
    },
    wrapperFlexBox: {
        backgroundColor: theme.palette.primary.main,
        paddingBottom: '40%',
        [theme.breakpoints.up('md')]: {
            backgroundImage: `url(${desktopWelcomeImg})`,
            backgroundColor: theme.palette.primary.light,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '30%',
            height: '100vh',
            overflow: 'auto',
            position: 'sticky',
            alignSelf: 'center',
        },
    },
    hogstMachine: {
        [theme.breakpoints.up('md')]: {
            width: 290,
            height: 'auto',
            margin: '0 auto',
            padding: '10px',
            position: 'relative',
            overflowY: 'auto',
        },
    },
    lassBearer: {
        [theme.breakpoints.up('md')]: {
            width: 290,
            height: 'auto',
            margin: '0 auto',
            padding: '10px',
            position: 'relative',
            overflowY: 'auto',
        },
    },
    cardContainerHogst: {
        [theme.breakpoints.up('md')]: {
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            width: 410,
            borderRadius: 20,
            position: 'absolute',
            minHeight: 460,
            marginTop: 50,
            left: 150,
            marginBottom: 200,
            overflowY: 'hidden',
        },
    },
    cardContainerLass: {
        [theme.breakpoints.up('md')]: {
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            width: 410,
            borderRadius: 20,
            position: 'absolute',
            minHeight: 460,
            marginTop: 50,
            right: 150,
            marginBottom: 200,
            overflowY: 'hidden',
        },
    },
    desktopText: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',

            color: theme.palette.text.primary,
        },
    },
    desktopTitle: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',

            fontSize: '96px',
            fontWeight: 500,
        },
    },
}));

interface Props {
    /** Mock data consisting of a list of machines, which furthermore contain general information about the machine */
    machines: IMachine[];
}

/**
 * Container for the machine accordion component used in the machines page, sorting the machines into the two machine types used in each order
 */
const MachinesComponent: FC<Props> = ({ machines }: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapperFlexBox}>
            <div className={classes.titleFlexBoxMain}>
                <Typography className={classes.desktopTitle} color="textSecondary" variant="h2">
                    Dine Maskiner
                </Typography>
            </div>
            <div>
                <div className={classes.cardContainerHogst}>
                    <div className={classes.hogstMachine}>
                        <div className={classes.titleFlexBox}>
                            <Typography className={classes.desktopText} color="textSecondary" variant="h2">
                                Hogstmaskiner
                            </Typography>
                        </div>
                        {machines
                            .filter((machine) => machine.prod_type === 'hogstmaskin')
                            .map((machine: IMachine, key: number) => {
                                return (
                                    <MachineAccordionDetails
                                        key={key}
                                        machineDetails={machine.machine_vals}
                                        machine={machine}
                                    />
                                );
                            })}
                    </div>
                </div>
                <div className={classes.cardContainerLass}>
                    <div className={classes.lassBearer}>
                        <div className={classes.titleFlexBox}>
                            <Typography className={classes.desktopText} color="textSecondary" variant="h2">
                                Lassebærere
                            </Typography>
                        </div>
                        {machines
                            .filter((machine) => machine.prod_type === 'lassebærer')
                            .map((machine: IMachine, key: number) => {
                                return (
                                    <MachineAccordionDetails
                                        key={key}
                                        machineDetails={machine.machine_vals}
                                        machine={machine}
                                    />
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MachinesComponent;
