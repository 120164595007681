export const mock_daily_machine_data = [
    {
        id: 1004602,
        trees_chopped: [87, 80, 86, 73, 84, 90, 100],
        driven: [40, 50, 60, 30, 55, 58, 49],
        date: ['2021-07-23', '2021-07-24', '2021-07-25', '2021-07-26', '2021-07-27', '2021-07-28', '2021-07-29'],
    },
    {
        id: 1004599,
        trees_chopped: [0, 0, 0, 0, 0, 0],
        driven: [0, 0, 0, 0, 0, 0],

        date: ['2021-08-31', '2021-09-01', '2021-09-02', '2021-07-03', '2021-09-04', '2021-09-05'],
    },
    {
        id: 1004595,
        trees_chopped: [807, 880, 886, 773, 884, 998],
        driven: [740, 750, 560, 730, 555, 779],
        date: ['2021-07-10', '2021-07-11', '2021-07-12', '2021-07-13', '2021-07-14', '2021-07-15'],
    },
    {
        id: 1003708,
        trees_chopped: [787, 680, 886, 763, 684, 698],
        driven: [450, 550, 660, 300, 505, 484],
        date: ['2021-04-15', '2021-04-16', '2021-04-17', '2021-04-18', '2021-04-19', '2021-04-20'],
    },
    {
        id: 1003706,
        trees_chopped: [870, 860, 686, 473, 684, 678],
        driven: [450, 850, 460, 430, 655, 611],
        date: ['2021-06-21', '2021-06-22', '2021-06-22', '2021-06-23', '2021-06-24', '2021-06-25'],
    },
    {
        id: 1003639,
        trees_chopped: [2487, 2580, 2386, 2473, 2484, 2896],
        driven: [2125, 2150, 2260, 2330, 1955, 2234],
        date: ['2021-06-01', '2021-06-02', '2021-06-03', '2021-06-04', '2021-06-05', '2021-06-06'],
    },
    {
        id: 1003638,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 1002606,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 1002203,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 1002202,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 1001603,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 1001228,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 1001097,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 1001072,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 1001035,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 1001014,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 1000969,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 1000666,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 1000665,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 1000279,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 1000275,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 180685,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 180618,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 180539,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 180256,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
    {
        id: 180253,
        trees_chopped: [1387, 1480, 1586, 1573, 1384, 1100],
        driven: [1240, 1150, 1260, 1230, 955, 1000],
        date: ['2021-03-10', '2021-03-11', '2021-03-12', '2021-03-13', '2021-03-14', '2021-03-15'],
    },
];
