import React, { FC } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        leaflet_container: {
            width: '100%',
            position: 'fixed',
            top: 55,
            zIndex: -998,
        },
        map_container: {
            height: '100vh',
        },
    }),
);

interface Props {
    /** Boolean deciding whether the map is interactable or not */
    interactable: boolean;
}

/**
 * Placeholder Leaflet map which later should contain the machines's routes, current position and other relevant geographical information from the current order
 */
const LeafletMap: FC<Props> = ({ interactable }: Props) => {
    const classes = useStyles();

    return (
        <div id="mainmap" className={classes.leaflet_container}>
            <MapContainer
                className={classes.map_container}
                center={[61, 11.01]}
                zoom={13}
                scrollWheelZoom={interactable}
                dragging={interactable}
                touchZoom={interactable}
                doubleClickZoom={interactable}
                boxZoom={interactable}
                keyboard={interactable}
                zoomControl={interactable}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
            </MapContainer>
        </div>
    );
};

export default LeafletMap;
