import React, { FC, Fragment } from 'react';
import { Icon, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Accordion, AccordionSummary, AccordionDetails } from './AccordionElements';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ChoppedIcon from '../Icons/chopped.svg';
import DrivenIcon from '../Icons/driven.svg';
import PickedUpIcon from '../Icons/picked_up.svg';
import { IKeyfigures } from '../models/keyfigures.interface';
import { IMatrixLines } from '../models/assortment.interface';
import HeatMapComponent from './HeatMap';

const useStyles = makeStyles((theme) => ({
    summaryTitleDiv: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    summarySecTitleDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    producedValue: {
        color: theme.palette.info.main,
    },
    shipppedValue: {
        color: theme.palette.info.light,
    },
    deliveredValue: {
        color: theme.palette.info.dark,
    },
    detailsContainer: {
        width: '100%',
        padding: '3%',
        display: 'flex',
        flexDirection: 'column',
    },
    detailsRoot: {
        width: '100%',
        paddingBottom: '5%',
    },
    detailsVal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: theme.palette.text.primary,
        borderBottom: `0.01px solid ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 'none',
        },
        marginTop: '6px',
        paddingBottom: '3px',
    },
    dropdownIcon: {
        color: theme.palette.text.primary,
    },
    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '6px',
    },
}));

interface Props {
    /** Key figures from order production */
    keyFigures: IKeyfigures;

    /** List of assortment matrices from harvester */
    assortmentMatrix: IMatrixLines[];
}

/**
 * Accordion displaying the different assortments and their key values from production, in addition to the belonging assortment matrix
 */
const AssortmentComponent: FC<Props> = (props: Props) => {
    const classes = useStyles();

    const { keyFigures, assortmentMatrix } = props;

    const assortments = keyFigures.lines;

    return (
        <Fragment>
            {assortments.map((assortment, key) => {
                return (
                    <Accordion key={key}>
                        <AccordionSummary
                            expandIcon={<ArrowDropDown className={classes.dropdownIcon} />}
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                        >
                            <div className={classes.summaryTitleDiv}>
                                <div>
                                    <Typography variant="h5" color="textSecondary">
                                        {`${assortment.assortmentNum} ${assortment.assortmentName}`}
                                    </Typography>
                                </div>
                                <div className={classes.summarySecTitleDiv}>
                                    <Icon className={classes.icon}>
                                        <img src={ChoppedIcon} height={12} width={13} alt="typeIcon" />
                                    </Icon>
                                    <Typography variant="h5" className={classes.producedValue}>
                                        {assortment.producedVolume}
                                    </Typography>
                                    <Icon className={classes.icon}>
                                        <img src={DrivenIcon} height={11} width={15} alt="typeIcon" />
                                    </Icon>
                                    <Typography variant="h5" className={classes.shipppedValue}>
                                        {assortment.shippedToRoadVolume}
                                    </Typography>
                                    <Icon className={classes.icon}>
                                        <img src={PickedUpIcon} height={12} width={17} alt="typeIcon" />
                                    </Icon>
                                    <Typography variant="h5" className={classes.deliveredValue}>
                                        {assortment.deliveredVolume}
                                    </Typography>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.detailsContainer}>
                                <div className={classes.detailsRoot}>
                                    {Object.entries(assortment)
                                        .filter(([key, value]) => typeof value === 'number' && key !== 'assortmentNum')
                                        .map(([key, value]) => {
                                            return (
                                                <div key={key} className={classes.detailsVal}>
                                                    <Typography variant="h5">
                                                        {key.replace(/([A-Z]+)/g, ' $1').toLowerCase()}
                                                    </Typography>
                                                    <Typography variant="h5">{value}</Typography>
                                                </div>
                                            );
                                        })}
                                </div>

                                <HeatMapComponent matrixLines={assortmentMatrix[2]} />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Fragment>
    );
};

export default AssortmentComponent;
