import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import logging from '../config/logging';
import IPage from '../interfaces/page';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import artwork from '../images/artwork.png';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Logo from '../Icons/Hogsten.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage: `url(${artwork})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100vh',
        paddingLeft: 30,
        paddingRight: 30,
    },
    logoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '10px',
    },
    logo: {
        color: '#FFFFFF',
    },
    textDiv: {
        paddingTop: '21%',
        paddingBottom: '20%',
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.common.white,
    },
    title: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '45px',
    },
    inputDiv: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    input: {
        marginBottom: '2%',
        height: '40px',
        paddingLeft: 10,
        border: `1px solid ${theme.palette.text.secondary}`,
        borderRadius: '20px',
        width: '100%',
    },
    forgotPassw: {
        marginTop: '-1.5%',
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
    },
    btnContainer: {
        width: '87%',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 40,
    },
    button: {
        backgroundColor: theme.palette.text.primary,
        color: theme.palette.text.secondary,
        borderRadius: '20px',
        width: '100%',
    },
    buttonText: {
        textTransform: 'none',
    },
    inputText: {
        color: theme.palette.text.secondary,
        textTransform: 'none',
    },
    link: {
        textDecoration: 'none',
        width: '100%',
    },
}));

const LoginPage = (props: IPage) => {
    logging.info(`Loading ${props.name}`);

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.logoWrapper}>
                <img src={Logo} alt="logo" width="103px" height="18px" />
            </div>

            <div className={classes.textDiv}>
                <Typography className={classes.title}>Hei!</Typography>
                <Typography variant="caption">
                    Velkommen til LOGO. Her kan du logge inn for å få oversikt over prosjektene dine.
                </Typography>
            </div>
            <div className={classes.inputDiv}>
                <Typography variant="button" className={classes.inputText}>
                    Skriv inn din epostadresse
                </Typography>
                <InputBase defaultValue="" inputProps={{ 'aria-label': 'naked' }} className={classes.input} />
                <Typography variant="button" className={classes.inputText}>
                    Skriv inn passordet ditt
                </Typography>
                <InputBase defaultValue="" inputProps={{ 'aria-label': 'naked' }} className={classes.input} />
                <div className={classes.forgotPassw}>
                    <Typography variant="h5" className={classes.inputText}>
                        Glemt passordet?
                    </Typography>
                </div>
            </div>
            <div className={classes.btnContainer}>
                <Link to={`/prosjekter`} className={classes.link}>
                    <Button variant="contained" className={classes.button}>
                        <Typography variant="button" className={classes.buttonText}>
                            Logg inn
                        </Typography>
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default withRouter(LoginPage);
