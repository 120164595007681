import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ProjectsComponent from '../Components/ProjectsComponent';
import logging from '../config/logging';
import IPage from '../interfaces/page';
import { user_info } from '../mock_data/user_info';
import { AxiosResponse } from 'axios';
import { Requests } from '../api/api';
import { IOrder } from '../models/order.interface';

const defaultOrders: IOrder[] = [];

const ProjectsPage = (props: IPage) => {
    //
    logging.info(`Loading ${props.name}`);

    const [orders, setOrders]: [IOrder[], (orders: IOrder[]) => void] = React.useState(defaultOrders);
    const [loading, setLoading]: [boolean, (loading: boolean) => void] = React.useState<boolean>(true);

    useEffect(() => {
        let isMounted = true;

        Requests.getToken().then((response: AxiosResponse) => {
            Requests.getOrders(response.data.access_token)
                .then((response: AxiosResponse) => {
                    if (isMounted) {
                        setOrders(response.data.orders);
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        });

        return () => {
            isMounted = false;
        };
    }, []);

    if (loading) {
        return <div className="App">Loading...</div>;
    }

    return (
        <Fragment>
            <ProjectsComponent orders={orders} user={user_info[0]} />
        </Fragment>
    );
};

export default withRouter(ProjectsPage);
