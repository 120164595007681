import React, { FC, Fragment } from 'react';
import { makeStyles } from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import darkTheme from '../darkTheme';
import Typography from '@material-ui/core/Typography';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import { IMatrixLines } from '../models/assortment.interface';

const useStyles = makeStyles((theme) => ({
    titleFlexBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1%',
        paddingTop: '4%',
        paddingBottom: '2%',
        backgroundColor: theme.palette.primary.main,
    },
    switchDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
}));

// Switch button

interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}

interface SwitchPropsBtn extends SwitchProps {
    classes: Styles;
}

const IOSSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            color: theme.palette.common.white,
            '&$checked': {
                transform: 'translateX(16px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#AE5FD3',
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                color: '#52d869',
                border: '6px solid #fff',
            },
        },
        thumb: {
            width: 24,
            height: 24,
        },
        track: {
            borderRadius: 26 / 2,
            backgroundColor: '#78788052',
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    }),
)(({ classes, ...props }: SwitchPropsBtn) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

// Heatmap

HighchartsHeatmap(Highcharts);

interface Props {
    /** Assortment matrix from harvester */
    matrixLines: IMatrixLines;
}

/**
 * Component containing a functional heatmap and toggle for displaying/hiding values
 */
const HeatMapComponent: FC<Props> = ({ matrixLines }: Props) => {
    const classes = useStyles();

    const [state, setState] = React.useState(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(!state);
    };

    type sumsList = {
        [key: number]: number;
    };

    const lengths = [28, 31, 34, 37, 40, 43, 46, 49, 52, 55, 58];

    const lenSums: sumsList = {};

    lengths.forEach((length) => {
        lenSums[length] = 0;
    });

    const diametres = matrixLines.matrix.diameterMetaItems.map((item) => {
        return item.diameter;
    });

    const diaSums = matrixLines.matrix.diameterMetaItems.map((item) => {
        return item.meta.sumLogs;
    });

    const mapData: number[][] = [];

    lengths.forEach((length) => {
        matrixLines.matrix.diameterMetaItems.forEach((item) => {
            mapData.push([lengths.indexOf(length), matrixLines.matrix.diameterMetaItems.indexOf(item), 0]);
        });
    });

    let y = 0;
    matrixLines.matrix.positionMatrix.forEach((matrix) => {
        const diaVal = matrixLines.matrix.positionMatrix.indexOf(matrix);

        if (
            matrixLines.matrix.positionMatrix.indexOf(matrix) !== 0 &&
            matrixLines.matrix.positionMatrix[diaVal - 1].diameter < matrix.diameter
        ) {
            y++;
        }

        lengths.forEach((length) => {
            if (matrix.length === length - 1 || matrix.length === length || matrix.length === length + 1) {
                mapData.forEach((data) => {
                    if (data[0] === lengths.indexOf(length) && data[1] === y) {
                        data[2] = data[2] + matrix.logs;
                        lenSums[length] = lenSums[length] + matrix.logs;
                    }
                });
            }
        });
    });

    const chartOptions = {
        chart: {
            type: 'heatmap',
            height: '175%',
            backgroundColor: darkTheme.palette.primary.main,
        },

        title: {
            text: '',
        },

        xAxis: [
            {
                categories: Object.values(lenSums),
                lineColor: darkTheme.palette.divider,
                opposite: false,
                tickColor: darkTheme.palette.divider,
                tickWidth: 1,
                tickLength: 24,
                title: {
                    text: 'Sum: ',
                    align: 'low',
                    offset: 0,
                    rotation: 0,
                    y: 8,
                    x: -33,
                    style: {
                        fontFamily: 'Poppins',
                        fontSize: '11px',
                        fontWeight: 700,
                        color: '#D18CF2',
                    },
                },
                labels: {
                    style: {
                        color: '#D18CF2',
                        fontFamily: 'Poppins',
                        fontWeight: 700,
                        fontSize: '12px',
                    },
                },
            },
            {
                categories: lengths,
                lineColor: darkTheme.palette.divider,
                linkedTo: 0,
                opposite: true,
                title: {
                    text: 'Stokklengde',
                    y: -6,
                    style: {
                        fontFamily: 'Poppins',
                        fontSize: '13px',
                        fontWeight: 700,
                        color: darkTheme.palette.primary.contrastText,
                    },
                },
                labels: {
                    style: {
                        fontFamily: 'Poppins',
                        fontSize: '13px',
                        fontWeight: 400,
                        color: darkTheme.palette.primary.contrastText,
                    },
                },
            },
        ],

        yAxis: [
            {
                categories: diametres,
                opposite: false,
                reversed: true,
                title: {
                    text: 'Diameter',
                    style: {
                        fontFamily: 'Poppins',
                        fontSize: '13px',
                        fontWeight: 700,
                        color: darkTheme.palette.primary.contrastText,
                    },
                },
                labels: {
                    style: {
                        fontFamily: 'Poppins',
                        fontSize: '13px',
                        fontWeight: 400,
                        color: darkTheme.palette.primary.contrastText,
                    },
                },
            },
            {
                categories: diaSums,
                linkedTo: 0,
                opposite: true,
                reversed: true,
                tickColor: darkTheme.palette.divider,
                tickWidth: 1,
                tickLength: 34,
                title: {
                    text: 'Sum: ',
                    align: 'high',
                    offset: 0,
                    rotation: 0,
                    y: -18,
                    style: {
                        fontFamily: 'Poppins',
                        fontSize: '11px',
                        fontWeight: 700,
                        color: '#D18CF2',
                    },
                },
                lineColor: darkTheme.palette.divider,
                gridLineColor: darkTheme.palette.divider,
                labels: {
                    style: {
                        color: '#D18CF2',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontWeight: 700,
                    },
                },
            },
        ],

        colorAxis: [
            {
                min: 0,
                minColor: 'rgba(0,0,0,0)',
            },
            {
                min: 1,
                minColor: state ? '#e4bff5' : 'rgba(228, 191, 245, 0.5)',
                maxColor: state ? '#830dba' : 'rgba(131, 13, 186, 0.5)',
            },
        ],

        legend: {
            enabled: false,
        },

        tooltip: {
            style: {
                display: 'none',
            },
        },

        plotOptions: {
            series: {
                states: {
                    inactive: {
                        opacity: 1,
                    },
                    hover: {
                        enabled: false,
                    },
                },
            },
        },

        series: [
            {
                cursor: 'pointer',
                point: {
                    events: {
                        click: function (e: React.ChangeEvent<HTMLInputElement>) {
                            handleChange(e);
                        },
                    },
                },

                name: 'null',
                borderWidth: 1,
                borderColor: darkTheme.palette.divider,
                data: mapData.filter((arr) => arr[2] === 0),
                dataLabels: {
                    enabled: false,
                },
            },

            {
                name: 'Stokkmatrise',
                cursor: 'pointer',
                point: {
                    events: {
                        click: function (e: React.ChangeEvent<HTMLInputElement>) {
                            handleChange(e);
                        },
                    },
                },
                colorAxis: 1,
                borderWidth: 1,
                borderColor: darkTheme.palette.divider,
                data: mapData.filter((arr) => arr[2] !== 0),
                dataLabels: {
                    enabled: !state,
                    color: darkTheme.palette.primary.contrastText,
                    style: { textOutline: 'none' },
                },
            },
        ],
    };

    return (
        <>
            <div className={classes.titleFlexBox}>
                <Typography variant="h3" color="textSecondary">
                    Stokkmatrise
                </Typography>
                <div className={classes.switchDiv}>
                    <Typography variant="h5" color="textSecondary">
                        Heatmap
                    </Typography>
                    <IOSSwitch checked={state} onChange={handleChange} name="checked" />
                </div>
            </div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </>
    );
};

export default HeatMapComponent;
