import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import NavbarBtn from './NavbarBtn';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Logo from '../Icons/Hogsten.svg';
import SearchIcon from '@material-ui/icons/Search';
import { ReactComponent as BrokenWorkIcon } from '../Icons/BrokenWorkIcon.svg';
import CloseIcon from '@material-ui/icons/Close';
import SearchComponent from './SearchComponent';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            width: '100%',
            position: 'fixed',
            top: 0,
            backgroundColor: theme.palette.primary.main,
        },
        barContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        menuButtonIcon: {
            color: theme.palette.text.secondary,
        },
        logo: {
            color: '#FFFFFF',
            fontSize: '16px',
        },
        desktopMode: {
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        mobileMode: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        headers: {
            color: theme.palette.text.secondary,
            fontSize: '12px',
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'inline-flex',
            },
        },
        button: {
            color: theme.palette.text.secondary,
            fontSize: '11px',
        },
        inline: {
            display: 'inline-flex',
            position: 'static',
        },
        icons: {
            width: 17,
            height: 16,
            paddingRight: 5,
        },
        checkIcon: {
            width: 16,
            height: 15,
            paddingRight: 5,
        },
        link: {
            textDecoration: 'none',
            color: 'inherit',
        },
    }),
);

/**
 * Navbar component at the top of the application which navigates to the projects-, machines-, and profile page
 */
const Navbar: FC = () => {
    const classes = useStyles();

    const [showResults, setShowResults] = React.useState(false);
    const handleSearch = () => setShowResults(!showResults);
    return (
        <>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.barContainer}>
                    <div className={classes.desktopMode}>
                        <NavbarBtn />
                    </div>

                    <img src={Logo} alt="logo" width="103px" height="18px" />

                    {!showResults ? <MenuItems /> : <SearchComponent />}

                    <IconButton className={classes.mobileMode} onClick={handleSearch}>
                        {!showResults ? <SearchIcon /> : <CloseIcon />}
                    </IconButton>
                    <IconButton className={classes.desktopMode} onClick={handleSearch}>
                        <SearchIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </>
    );
};

const MenuItems = (): React.ReactElement => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="body2" className={classes.headers}>
                <Link className={classes.link} to={`/prosjekter`} replace>
                    <div className={classes.inline}>
                        <WorkOutlineIcon className={classes.icons} />
                        Aktive Prosjekter
                    </div>
                </Link>
            </Typography>
            <Typography variant="body2" className={classes.headers}>
                <Link className={classes.link} to={`/prosjekter`} replace>
                    <div className={classes.inline}>
                        <BrokenWorkIcon className={classes.checkIcon} />
                        Fullførte Prosjekter
                    </div>
                </Link>
            </Typography>
            <Typography variant="body2" className={classes.headers}>
                <Link className={classes.link} to={`/maskiner`} replace>
                    <div className={classes.inline}>
                        <LocalShippingIcon className={classes.icons} />
                        Maskiner
                    </div>
                </Link>
            </Typography>

            <Typography variant="body2" className={classes.headers}>
                <Link className={classes.link} to={`/profil`} replace>
                    <div className={classes.inline}>
                        <PersonOutlineIcon className={classes.icons} />
                        Profil
                    </div>
                </Link>
            </Typography>
        </>
    );
};

export default Navbar;
