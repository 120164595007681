export const all_data = [
    {
        project_id: '96b69a4e-fa65-4cd0-b355-dbe7aa07f539',
        machine_id: 'abc',
        machine_driver: 'Jonas Maskinfører',
        machine_vals_id: 1,
        machine_vals: {
            Middelstamme: 0.35,
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Antall avbrudd': 3,
            'Total timer i drift': 100,
        },
    },
    {
        project_id: '96b69a4e-fa65-4cd0-b355-dbe7aa07f539',
        machine_id: 'def',
        machine_driver: 'Stine Maskinfører',
        machine_vals_id: 2,
        machine_vals: {
            Middelstamme: 0.35,
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Antall avbrudd': 3,
            'Total timer i drift': 100,
        },
    },
    {
        project_id: '465031e7-963c-42be-a17f-7b08630a80cc',
        machine_id: 'asd',
        machine_driver: 'Kai Maskinfører',
        machine_vals_id: 3,
        machine_vals: {
            Middelstamme: 0.35,
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Antall avbrudd': 3,
            'Total timer i drift': 100,
        },
    },
    {
        project_id: '465031e7-963c-42be-a17f-7b08630a80cc',
        machine_id: '3mj',
        machine_driver: 'Elin Maskinfører',
        machine_vals_id: 4,
        machine_vals: {
            Middelstamme: 0.35,
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Antall avbrudd': 3,
            'Total timer i drift': 100,
        },
    },
    {
        project_id: 'dcbd9bab-173b-44ea-9d2b-3021396daed1',
        machine_id: '3hj',
        machine_driver: 'Rikke Maskinfører',
        machine_vals_id: 5,
        machine_vals: {
            Middelstamme: 0.35,
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Antall avbrudd': 3,
            'Total timer i drift': 100,
        },
    },
    {
        project_id: 'dcbd9bab-173b-44ea-9d2b-3021396daed1',
        machine_id: '0dj',
        machine_driver: 'Line Maskinfører',
        machine_vals_id: 6,
        machine_vals: {
            Middelstamme: 0.35,
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Antall avbrudd': 3,
            'Total timer i drift': 100,
        },
    },
    {
        project_id: '979faf38-c974-4885-a998-4fd111ee88d9',
        machine_id: '3kj',
        machine_driver: 'Jonas Maskinfører',
        machine_vals_id: 7,
        machine_vals: {
            Middelstamme: 0.35,
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Antall avbrudd': 3,
            'Total timer i drift': 100,
        },
    },
    {
        project_id: '979faf38-c974-4885-a998-4fd111ee88d9',
        machine_id: 'sjh',
        machine_driver: 'Jonas Maskinfører',
        machine_vals_id: 8,
        machine_vals: {
            Middelstamme: 0.35,
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Antall avbrudd': 3,
            'Total timer i drift': 100,
        },
    },
    {
        project_id: 'c7ec15e8-7f6d-496f-a679-6adae5e0ed8b',
        machine_id: 'abc',
        machine_driver: 'Jonas Maskinfører',
        machine_vals_id: 9,
        machine_vals: {
            Middelstamme: 0.35,
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Antall avbrudd': 3,
            'Total timer i drift': 100,
        },
    },
    {
        project_id: 'c7ec15e8-7f6d-496f-a679-6adae5e0ed8b',
        machine_id: 'def',
        machine_driver: 'Jonas Maskinfører',
        machine_vals_id: 10,
        machine_vals: {
            Middelstamme: 0.35,
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Antall avbrudd': 3,
            'Total timer i drift': 100,
        },
    },
    {
        project_id: 'cd51070f-9fd0-4a26-9931-63539c31c3aa',
        machine_id: 'asd',
        machine_driver: 'Jonas Maskinfører',
        machine_vals_id: 11,
        machine_vals: {
            Middelstamme: 0.35,
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Antall avbrudd': 3,
            'Total timer i drift': 100,
        },
    },
    {
        project_id: 'cd51070f-9fd0-4a26-9931-63539c31c3aa',
        machine_id: '3mj',
        machine_driver: 'Jonas Maskinfører',
        machine_vals_id: 12,
        machine_vals: {
            Middelstamme: 0.35,
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Antall avbrudd': 3,
            'Total timer i drift': 100,
        },
    },
    {
        project_id: 'e4d80a8b-ac33-4bf7-bf4b-1481825fb30b',
        machine_id: '3hj',
        machine_driver: 'Jonas Maskinfører',
        machine_vals_id: 13,
        machine_vals: {
            Middelstamme: 0.35,
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Antall avbrudd': 3,
            'Total timer i drift': 100,
        },
    },
    {
        project_id: 'e4d80a8b-ac33-4bf7-bf4b-1481825fb30b',
        machine_id: '0dj',
        machine_driver: 'Jonas Maskinfører',
        machine_vals_id: 14,
        machine_vals: {
            Middelstamme: 0.35,
            'Antall trær': 780,
            'Drivstofforbruk/motortimer': 9.5,
            'Drivstofforbruk/E': 10,
            'Teknisk ytelsesgrad': 95,
            'Tilgjengelig planlagt arbeidstid': 95,
            'Antall avbrudd': 3,
            'Total timer i drift': 100,
        },
    },
];
