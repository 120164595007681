import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import logging from '../config/logging';
import IPage from '../interfaces/page';
import LeafletMap from '../Components/LeafletMap';
import { Requests } from '../api/api';
import { AxiosResponse } from 'axios';
import { IOrder } from '../models/order.interface';
import { IKeyfigures } from '../models/keyfigures.interface';
import { IMatrixLines } from '../models/assortment.interface';
import { useRef } from 'react';
import StickyDrawer from '../Components/StickyDrawer';
import { createStyles, makeStyles } from '@material-ui/core';
import ProjectCard from '../Components/ProjectCard';

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            backgroundColor: theme.palette.primary.main,
            borderRadius: '9px 9px 0px 0px',
            height: '100%',
        },
        projectCardWrapper: {
            position: 'fixed',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '9px 9px 0px 0px',
            bottom: 0,
            width: '100%',
        },
    }),
);

const ProjectPage = (props: IPage) => {
    const classes = useStyles();

    logging.info(`Loading ${props.name}`);

    const projectID = props.match.params.projectID;
    const bearer = useRef('');
    const orderNum = useRef(0);
    const orderId = useRef('');

    const defaultOrders: IOrder[] = [];
    const defaultKeyFigures: IKeyfigures[] = [];
    const defaultMatrix: IMatrixLines[] = [];

    const [loading, setLoading]: [boolean, (loading: boolean) => void] = React.useState<boolean>(true);
    const [orders, setOrders]: [IOrder[], (orders: IOrder[]) => void] = React.useState(defaultOrders);
    const [keyFigures, setKeyFigures]: [IKeyfigures, (keyFigures: IKeyfigures) => void] = React.useState(
        defaultKeyFigures[0],
    );
    const [matrix, setMatrix]: [IMatrixLines[], (matrix: IMatrixLines[]) => void] = React.useState(defaultMatrix);

    const [interactable, setInteractable] = React.useState(false);

    useEffect(() => {
        let isMounted = true;

        Requests.getToken().then((response: AxiosResponse) => {
            bearer.current = response.data.access_token;

            Requests.getOrders(response.data.access_token)
                .then((response: AxiosResponse) => {
                    if (isMounted) {
                        setOrders(response.data.orders);

                        orderNum.current = response.data.orders.filter(function (order: IOrder) {
                            return order.orderId === projectID;
                        })[0].orderNum;

                        orderId.current = response.data.orders.filter(function (order: IOrder) {
                            return order.orderId === projectID;
                        })[0].orderId;

                        Requests.getAssortmentMatrix(bearer.current)
                            .then((response: AxiosResponse) => {
                                if (isMounted) {
                                    setMatrix(response.data.matrixLines);
                                    setLoading(false);
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            Requests.getOrder(response.data.access_token, projectID)
                .then((response: AxiosResponse) => {
                    if (isMounted) {
                        setKeyFigures(response.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        });

        return () => {
            isMounted = false;
        };
    }, [projectID]);

    const handleClick = () => {
        setInteractable(!interactable);
    };

    if (loading) {
        return <div className="App">Loading...</div>;
    }

    return (
        <Fragment>
            <div className={classes.container}>
                {interactable ? (
                    <>
                        <LeafletMap interactable={interactable} />
                        <div className={classes.projectCardWrapper} onClick={() => handleClick()}>
                            <ProjectCard
                                order={
                                    orders.filter((order) => {
                                        return order.orderId === orderId.current;
                                    })[0]
                                }
                                orderID={orderId.current}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div onClick={() => handleClick()}>
                            <LeafletMap interactable={interactable} />
                        </div>
                        <StickyDrawer orders={orders} keyFigures={keyFigures} page={props} assortmentMatrix={matrix} />
                    </>
                )}
            </div>
        </Fragment>
    );
};

export default withRouter(ProjectPage);
