import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import ProfileComponent from '../Components/ProfileComponent';
import logging from '../config/logging';
import IPage from '../interfaces/page';
import { user_info } from '../mock_data/user_info';
import { machines } from '../mock_data/machine_info';

const ProfilePage = (props: IPage) => {
    logging.info(`Loading ${props.name}`);

    const user = user_info[0];

    return <Fragment>{<ProfileComponent machines={machines} user={user} />}</Fragment>;
};

export default withRouter(ProfilePage);
