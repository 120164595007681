import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import ChoppedIcon from '../Icons/chopped.svg';
import DrivenIcon from '../Icons/driven.svg';
import PickedUpIcon from '../Icons/picked_up.svg';
import LinearProgressbar from './LinearProgressbar';
import LineChart from './LineChart';
import { IDailyReports } from '../interfaces/IDailyReport';
import { mock_daily_machine_data } from '../mock_data/daily_machine_report';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import { IOrder } from '../models/order.interface';
import { IKeyfigures } from '../models/keyfigures.interface';
import { Requests } from '../api/api';
import { AxiosResponse } from 'axios';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        borderBottom: `0.01px solid ${theme.palette.divider}`,
        padding: '4%',
        paddingBottom: '2%',
        [theme.breakpoints.up('md')]: {
            backgroundColor: '#031401',
            borderRadius: 10,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'space-between',
            margin: '25px',
        },
    },
    rootDesktop: {
        [theme.breakpoints.up('md')]: {
            display: 'inline-flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            width: '100%',
        },
    },
    projectFlexBox: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
            width: '40vw',
        },
    },
    forestDetailsFlexBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        [theme.breakpoints.up('md')]: {
            paddingBottom: 40,
        },
    },
    forestDetailsFlexBoxDesktop: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'row',
        },
    },
    title: {
        color: theme.palette.text.secondary,
        [theme.breakpoints.up('md')]: {
            paddingBottom: 15,
        },
    },
    titleBox: {
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
        },
    },
    projectDetails: {
        color: theme.palette.text.primary,
        width: '40%',
    },
    progressFlexBox: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: '50%',
        [theme.breakpoints.up('md')]: {
            width: '82%',
        },
    },
    progressValuesFlexBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        textAlign: 'right',
        float: 'right',
        paddingRight: '2%',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    producedValue: {
        color: theme.palette.info.main,
    },
    shipppedValue: {
        color: theme.palette.info.light,
    },
    deliveredValue: {
        color: theme.palette.info.dark,
    },
    progressIconsFlexBox: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: '2%',
        [theme.breakpoints.up('md')]: {
            paddingRight: '4%',
        },
    },
    progressBarsFlexBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '100%',
    },
    volumeTotalFlexBox: {
        display: 'flex',
        height: '100%',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
        },
    },
    volumeTotal: {
        textAlign: 'right',
        float: 'right',
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.up('md')]: {
            paddingLeft: 35,
            display: 'inline-flex',
        },
    },
    lineChart: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'inline-flex',
            float: 'right',
            marignBottom: 340,
            marginRight: 20,
            height: 250,
            paddingTop: 90,
        },
    },
    progressValueTextDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'inline-flex',
            justifyContent: 'space-between',
            width: '100% ',
        },
    },
    hiddenSlash: {
        display: 'none',
        color: theme.palette.text.primary,
        [theme.breakpoints.up('md')]: {
            display: 'inline-flex',
        },
    },
    progressTypography: {
        [theme.breakpoints.up('md')]: {
            paddingBottom: 15,
        },
    },
    imageSize: {
        height: 20,
        width: 20,
        [theme.breakpoints.up('md')]: {
            height: 35,
            width: 24,
        },
    },
    arrowRounder: {
        display: 'none',
        bottom: '10px',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            float: 'right',
            backgroundColor: '#5E8359',
            borderRadius: 40,
            height: 54,
            width: 54,
            color: '#ECF7DD',
        },
    },
    footer: {
        [theme.breakpoints.up('md')]: {
            bottom: 0,
            width: '100%',
            height: '10%',
            paddingTop: 140,
        },
    },
    fremDrift: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    link: {
        textDecoration: 'none',
        pointerEvents: 'auto',
    },
}));

interface Props {
    /** General information about an order */
    order: IOrder;

    /** The unique identifier of an order */
    orderID: string;
}
const report: IDailyReports[] = mock_daily_machine_data;

/**
 * Component displaying the key information about an order and it's production
 */
const ProjectCard: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const { order, orderID } = props;

    const defaultKeyFigures: IKeyfigures[] = [];

    const [loading, setLoading]: [boolean, (loading: boolean) => void] = React.useState<boolean>(true);
    const [, setKeyFigures]: [IKeyfigures, (keyFigures: IKeyfigures) => void] = React.useState(defaultKeyFigures[0]);
    const [, setError]: [string, (error: string) => void] = React.useState('');

    const [produced, setProduced] = React.useState<number>(0);
    const [shipped, setShipped] = React.useState<number>(0);
    const [delivered, setDelivered] = React.useState<number>(0);

    useEffect(() => {
        let isMounted = true;

        Requests.getToken().then((response: AxiosResponse) => {
            Requests.getOrder(response.data.access_token, orderID)
                .then((response: AxiosResponse) => {
                    if (isMounted) {
                        setKeyFigures(response.data);

                        setProduced(
                            response.data.lines
                                .map((keyInfo: { producedVolume: number }) => keyInfo.producedVolume)
                                .reduce((x: number, y: number) => x + y, 0),
                        );
                        setShipped(
                            response.data.lines
                                .map((keyInfo: { shippedToRoadVolume: number }) => keyInfo.shippedToRoadVolume)
                                .reduce((x: number, y: number) => x + y, 0),
                        );
                        setDelivered(
                            response.data.lines
                                .map((keyInfo: { deliveredVolume: number }) => keyInfo.deliveredVolume)
                                .reduce((x: number, y: number) => x + y, 0),
                        );
                        setLoading(false);
                    }
                })
                .catch((err: string) => {
                    setError(err);
                    setLoading(false);
                });
        });

        return () => {
            isMounted = false;
        };
    }, [orderID]);

    const getProgress = (progress: number, total: number) => {
        if ((progress / total) * 100 > 100) {
            return 100;
        } else {
            return (progress / total) * 100;
        }
    };

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <div className={classes.root}>
            <div className={classes.rootDesktop}>
                <div>
                    <div className={classes.projectFlexBox}>
                        <div className={classes.forestDetailsFlexBox}>
                            <div className={classes.titleBox}>
                                <Typography variant="h3" color="textSecondary" className={classes.title}>
                                    {order.originalSupplierName}
                                </Typography>
                            </div>
                            <div className={classes.forestDetailsFlexBoxDesktop}>
                                <Typography variant="h5" color="textPrimary">
                                    Tømmernr: {order.muniName}
                                </Typography>
                                <Typography variant="h5" color="textPrimary" className={classes.hiddenSlash}>
                                    &nbsp;|&nbsp;
                                </Typography>
                                <Typography variant="h5" color="textPrimary" className={classes.projectDetails}>
                                    {order.buyerPartyName}
                                </Typography>
                            </div>
                        </div>
                        <Typography variant="h3" className={` ${classes.title} ${classes.fremDrift}`}>
                            Fremdrift
                        </Typography>
                        <div className={classes.progressFlexBox}>
                            <div className={classes.progressValuesFlexBox}>
                                <Typography variant="h5" className={classes.producedValue}>
                                    {produced}
                                </Typography>
                                <Typography variant="h5" className={classes.shipppedValue}>
                                    {shipped}
                                </Typography>
                                <Typography variant="h5" className={classes.deliveredValue}>
                                    {delivered}
                                </Typography>
                            </div>
                            <div className={classes.progressIconsFlexBox}>
                                <Icon className={classes.progressTypography}>
                                    <img src={ChoppedIcon} className={classes.imageSize} alt="chopped" />
                                </Icon>

                                <Icon className={classes.progressTypography}>
                                    <img src={DrivenIcon} className={classes.imageSize} alt="driven" />
                                </Icon>

                                <Icon className={classes.progressTypography}>
                                    <img src={PickedUpIcon} className={classes.imageSize} alt="picked up" />
                                </Icon>
                            </div>
                            <div className={classes.progressBarsFlexBox}>
                                <Typography
                                    variant="body2"
                                    component="span"
                                    color="textSecondary"
                                    className={classes.progressTypography}
                                >
                                    <LinearProgressbar
                                        progress={getProgress(produced, order.volume)}
                                        class={'produced'}
                                    />

                                    <div className={classes.progressValueTextDesktop}>
                                        <Typography variant="h5">Hugget volum</Typography>
                                        <Typography variant="h5" className={classes.producedValue}>
                                            {produced}/{order.volume}
                                        </Typography>
                                    </div>
                                </Typography>
                                <Typography
                                    variant="body2"
                                    component="span"
                                    color="textSecondary"
                                    className={classes.progressTypography}
                                >
                                    <LinearProgressbar
                                        progress={getProgress(shipped, order.volume)}
                                        class={'shipped'}
                                    />
                                    <div className={classes.progressValueTextDesktop}>
                                        <Typography variant="h5">Framkjørt volum</Typography>

                                        <Typography variant="h5" className={classes.shipppedValue}>
                                            {shipped}/{order.volume}
                                        </Typography>
                                    </div>
                                </Typography>
                                <Typography
                                    variant="body2"
                                    component="span"
                                    color="textSecondary"
                                    className={classes.progressTypography}
                                >
                                    <LinearProgressbar
                                        progress={getProgress(delivered, order.volume)}
                                        class={'delivered'}
                                    />
                                    <div className={classes.progressValueTextDesktop}>
                                        <Typography variant="h5">Transportert volum</Typography>

                                        <Typography variant="h5" className={classes.deliveredValue}>
                                            {delivered}/{order.volume}
                                        </Typography>
                                    </div>
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.lineChart}>
                    <LineChart width={600} height={200} order={order} report={report} />
                </div>

                <div className={classes.footer}>
                    <Link to={`/prosjekter/${order.orderId}`} className={classes.link}>
                        <ArrowForwardRoundedIcon className={classes.arrowRounder} />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ProjectCard;
