import axios from 'axios';
import { IOrder } from '../models/order.interface';

// const responseBody = (response: AxiosResponse) => response.data;

const username = process.env.REACT_APP_FELTGIS_USERNAME;
const password = process.env.REACT_APP_FELTGIS_PASSWORD;

const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
};

export const Requests = {
    // Bearer token

    getToken: (): Promise<any> =>
        axios.post(
            'https://nortommer.stg.vsys.no/vh//api/v1.1/Authorization/login/',
            `username=${username}&password=${password}&grant_type=password`,
            {
                headers: headers,
            },
        ),

    // Assortment matrix w/ data
    getAssortmentMatrix: (bearer: string): Promise<any> =>
        axios.get(
            `https://nortommer.stg.vsys.no/vh//api/v2.0-beta/rest/MeasuringTicket/LogMatrix/Assortment/Order/1003706/MeasuringTicket/805ca7a7-5e4a-43df-a8c2-c069a9a5139b`,
            {
                headers: {
                    Authorization: `Bearer ${bearer}`,
                },
            },
        ),

    // Assortment matrix actual
    getAssortmentMatrix_: (bearer: string, orderNum: number, orderId: string): Promise<any> =>
        axios.get(
            `https://nortommer.stg.vsys.no/vh//api/v2.0-beta/rest/MeasuringTicket/LogMatrix/Assortment/Order/${orderNum}/MeasuringTicket/${orderId}`,
            {
                headers: {
                    Authorization: `Bearer ${bearer}`,
                },
            },
        ),

    // Orders
    getOrders: (bearer: string): Promise<any> =>
        axios.put<IOrder[]>(
            'https://nortommer.stg.vsys.no/vh//api/v1.1/rest/Order/ContractorOrders',
            { purchaseOrderNum: 0 },
            {
                headers: {
                    Authorization: `Bearer ${bearer}`,
                },
            },
        ),

    // Order
    getOrder: (bearer: string, orderId: string): Promise<any> =>
        axios.get<IOrder>(
            `https://nortommer.stg.vsys.no/vh//api/v2.0-beta/rest/KeyFigures?orderId=${orderId}&orderType=CONTRACTOR`,
            {
                headers: {
                    Authorization: `Bearer ${bearer}`,
                },
            },
        ),
};
