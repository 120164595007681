import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { styled, alpha } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            borderColor: theme.palette.common.white,
        },
    }),
);

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: 20,
    borderColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: alpha(theme.palette.primary.dark, 0.15),
    '&:hover': {
        // backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    font: 'Poppins',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 15,
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '450px',
            '&:focus': {
                width: '500px',
            },
        },
    },
}));

/**
 * Searchbar component
 */
const SearchComponent = (): any => {
    const classes = useStyles();
    return (
        <div>
            <Search className={classes.search}>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    style={{ paddingLeft: 50 }}
                    placeholder="Søk etter navn på skogeiere eller innkjøpsordrenummer..."
                    inputProps={{ 'aria-label': 'search' }}
                />
            </Search>
        </div>
    );
};

export default SearchComponent;
