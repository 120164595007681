import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import ChoppedIcon from '../Icons/chopped.svg';
import DrivenIcon from '../Icons/driven.svg';
import PickedUpIcon from '../Icons/picked_up.svg';
import LinearProgressbar from './LinearProgressbar';
import { IOrder } from '../models/order.interface';
import { IKeyfigures } from '../models/keyfigures.interface';
import { Requests } from '../api/api';
import { AxiosResponse } from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '25px',
        padding: '4%',
        paddingBottom: '2%',
    },
    projectFlexBox: {
        display: 'flex',
        flexDirection: 'column',
    },
    forestDetailsFlexBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    detailsRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '90%',
        marginTop: '7px',
        marginBottom: '20px',
    },
    progressFlexBox: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
    },
    progressValuesFlexBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        textAlign: 'right',
        float: 'right',
    },
    producedValue: {
        color: theme.palette.info.main,
    },
    shipppedValue: {
        color: theme.palette.info.light,
    },
    deliveredValue: {
        color: theme.palette.info.dark,
    },
    progressIconsFlexBox: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '2px',
        paddingRight: '2px',
    },
    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    progressBarsFlexBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '100%',
    },
}));

interface Props {
    /** General information about an order */
    order: IOrder;

    /** The unique identifier of an order */
    orderID: string;
}

/**
 * Component displaying the key information about an order and it's production
 */
const ProjectCardDrawer: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const { order, orderID } = props;

    const defaultKeyFigures: IKeyfigures[] = [];

    const [loading, setLoading]: [boolean, (loading: boolean) => void] = React.useState<boolean>(true);
    const [, setKeyFigures]: [IKeyfigures, (keyFigures: IKeyfigures) => void] = React.useState(defaultKeyFigures[0]);
    const [, setError]: [string, (error: string) => void] = React.useState('');

    const [produced, setProduced] = React.useState<number>(0);
    const [shipped, setShipped] = React.useState<number>(0);
    const [delivered, setDelivered] = React.useState<number>(0);

    useEffect(() => {
        let isMounted = true;

        Requests.getToken().then((response: AxiosResponse) => {
            Requests.getOrder(response.data.access_token, orderID)
                .then((response: AxiosResponse) => {
                    if (isMounted) {
                        setKeyFigures(response.data);

                        setProduced(
                            response.data.lines
                                .map((keyInfo: { producedVolume: number }) => keyInfo.producedVolume)
                                .reduce((x: number, y: number) => x + y, 0),
                        );
                        setShipped(
                            response.data.lines
                                .map((keyInfo: { shippedToRoadVolume: number }) => keyInfo.shippedToRoadVolume)
                                .reduce((x: number, y: number) => x + y, 0),
                        );
                        setDelivered(
                            response.data.lines
                                .map((keyInfo: { deliveredVolume: number }) => keyInfo.deliveredVolume)
                                .reduce((x: number, y: number) => x + y, 0),
                        );
                        setLoading(false);
                    }
                })
                .catch((err: string) => {
                    setError(err);
                    setLoading(false);
                });
        });

        return () => {
            isMounted = false;
        };
    }, [orderID]);

    const getProgress = (progress: number, total: number) => {
        if ((progress / total) * 100 > 100) {
            return 100;
        } else {
            return (progress / total) * 100;
        }
    };

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <div className={classes.root}>
            <div className={classes.projectFlexBox}>
                <div className={classes.forestDetailsFlexBox}>
                    <Typography variant="h3" color="textSecondary">
                        {order.originalSupplierName}
                    </Typography>
                    <div className={classes.detailsRow}>
                        <Typography variant="h5" color="textPrimary">
                            Tømmernr: {order.connectedTradeOrderNum}
                        </Typography>
                        <Typography variant="h5" color="textPrimary">
                            │
                        </Typography>
                        <Typography variant="h5" color="textPrimary">
                            {order.buyerPartyName}
                        </Typography>
                        <Typography variant="h5" color="textPrimary">
                            │
                        </Typography>
                        <Typography variant="h5" color="textPrimary">
                            {order.muniName}
                        </Typography>
                    </div>
                </div>
                <div className={classes.progressFlexBox}>
                    <div className={classes.progressValuesFlexBox}>
                        <Typography variant="h5" className={classes.producedValue}>
                            {produced}/{order.volume}
                        </Typography>
                        <Typography variant="h5" className={classes.shipppedValue}>
                            {shipped}/{order.volume}
                        </Typography>
                        <Typography variant="h5" className={classes.deliveredValue}>
                            {delivered}/{order.volume}
                        </Typography>
                    </div>
                    <div className={classes.progressIconsFlexBox}>
                        <Icon className={classes.icon}>
                            <img src={ChoppedIcon} height={13} width={14.3} alt="chopped" />
                        </Icon>

                        <Icon className={classes.icon}>
                            <img src={DrivenIcon} height={12} width={17} alt="driven" />
                        </Icon>

                        <Icon className={classes.icon}>
                            <img src={PickedUpIcon} height={12} width={17} alt="picked up" />
                        </Icon>
                    </div>
                    <div className={classes.progressBarsFlexBox}>
                        <LinearProgressbar progress={getProgress(produced, order.volume)} class={'produced'} />
                        <LinearProgressbar progress={getProgress(shipped, order.volume)} class={'shipped'} />
                        <LinearProgressbar progress={getProgress(delivered, order.volume)} class={'delivered'} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectCardDrawer;
