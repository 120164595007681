import React from 'react';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 6,
            borderRadius: 9,
            width: '100%',
        },
    }),
)(LinearProgress);

const useStyles = makeStyles((theme: Theme) => ({
    produced: {
        '& .MuiLinearProgress-colorPrimary': {
            backgroundColor: theme.palette.info.contrastText,
        },
        '& .MuiLinearProgress-barColorPrimary': {
            background: `linear-gradient(90deg, ${theme.palette.info.main} 0%, #DA9898 125%)`,
        },
    },
    shipped: {
        '& .MuiLinearProgress-colorPrimary': {
            backgroundColor: theme.palette.info.contrastText,
        },
        '& .MuiLinearProgress-barColorPrimary': {
            background: `linear-gradient(90deg, ${theme.palette.info.light} 0%, #FFDB95 125%)`,
        },
    },
    delivered: {
        '& .MuiLinearProgress-colorPrimary': {
            backgroundColor: theme.palette.info.contrastText,
        },
        '& .MuiLinearProgress-barColorPrimary': {
            background: `linear-gradient(90deg, ${theme.palette.info.dark} 0%, #ACEBFF 125%)`,
        },
    },
}));

interface Props {
    /** Percentage progress from 0 - 100 */
    progress: number;

    /** Either of the classes 'produced', 'shipped' or 'delivered', which determines the progress bar's color */
    class: string;
}

/**
 * Reusable singular progress bar component used for indicating the progress for the three key values: produced, shipped and delivered
 */
const LinearProgressbar: React.FC<Props> = (props: Props) => {
    const classes = useStyles(props);

    if (props.class === 'produced') {
        return (
            <div className={classes.produced}>
                <BorderLinearProgress variant="determinate" value={props.progress} />
            </div>
        );
    } else if (props.class === 'shipped') {
        return (
            <div className={classes.shipped}>
                <BorderLinearProgress variant="determinate" value={props.progress} />
            </div>
        );
    } else {
        return (
            <div className={classes.delivered}>
                <BorderLinearProgress variant="determinate" value={props.progress} />
            </div>
        );
    }
};

export default LinearProgressbar;
