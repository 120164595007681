import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import React, { FC } from 'react';
import axeIcon from '../Icons/chopped.svg';
import logsIcon from '../Icons/driven.svg';
import { IMachine } from '../interfaces/machine';
import { IMachineProd } from '../interfaces/machine_prod';
import { Accordion, AccordionSummary, AccordionDetails } from './AccordionElements';
import { IMachineValsTotal } from '../interfaces/machinevalstotal';
import { IMachineVals } from '../interfaces/machinevalsprod';

const useStyles = makeStyles((theme) => ({
    summaryTitleDiv: {
        display: 'flex',
        flexDirection: 'row',
    },
    summarySubTitleDiv: {
        display: 'flex',
        flexDirection: 'column',
    },
    detailsRoot: {
        width: '100%',
        padding: '3%',
        paddingBottom: '5%',
    },
    detailsVal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: theme.palette.text.primary,
        borderBottom: `0.01px solid ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 'none',
        },
        marginTop: '6px',
        paddingBottom: '3px',
    },
    dropDownIcon: {
        color: theme.palette.text.primary,
    },
    machineIcon: {
        fontSize: 17,
        paddingRight: '3px',
    },
}));

interface Props {
    /** Mock data consisting of general information about a machine */
    machine: IMachine;

    /** Mock data connecting a machine to an order, including the machine driver */
    projectDetails?: IMachineProd;

    /** Mock data consisting of machine values, either order-specific (project page) or overall (machines page) */
    machineDetails: IMachineVals | IMachineValsTotal;
}

/**
 * Accordion displaying a machine and their specific machine details like fuel, kilometres driven etc.
 */
const MachineAccordionDetails: FC<Props> = (props: Props) => {
    const classes = useStyles();

    const { projectDetails, machineDetails, machine } = props;

    let driver = '';
    projectDetails ? (driver = projectDetails.machine_driver) : (driver = 'Ingen maskinfører'); // Should crosscheck if machine is active in a project

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ArrowDropDown className={classes.dropDownIcon} />}
                aria-controls="panel1d-content"
                id="panel1d-header"
            >
                <div>
                    <div className={classes.summaryTitleDiv}>
                        <Icon className={classes.machineIcon}>
                            <img
                                src={machine.prod_type === 'hogstmaskin' ? axeIcon : logsIcon}
                                height={17}
                                width={17}
                                alt="typeIcon"
                            />
                        </Icon>
                        <Typography variant="h4" color="textSecondary">
                            {props.machine.machine_type}
                        </Typography>
                    </div>
                    <div className={classes.summarySubTitleDiv}>
                        <Typography variant="h5" color="textPrimary">
                            {driver}
                        </Typography>
                        <Typography variant="h5" color="textPrimary">{`FeltGIS: ${props.machine.feltGIS}`}</Typography>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.detailsRoot}>
                    {Object.keys(machineDetails).map(function (key, index) {
                        return (
                            <div key={index} className={classes.detailsVal}>
                                <Typography variant="h5">{key}</Typography>
                                <Typography variant="h5">{machineDetails[key]}</Typography>
                            </div>
                        );
                    })}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default MachineAccordionDetails;
