import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MachinesAccordion from './MachinesAccordion';
import IPage from '../interfaces/page';
import LineChart from './LineChart';
import { mock_daily_machine_data } from '../mock_data/daily_machine_report';
import { IDailyReports } from '../interfaces/IDailyReport';
import Typography from '@material-ui/core/Typography';
import AssortmentComponent from './AssortmentComponent';
import { IOrder } from '../models/order.interface';
import { IKeyfigures } from '../models/keyfigures.interface';
import { IMatrixLines } from '../models/assortment.interface';
import ProjectCardDrawer from './ProjectCardDrawer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrap: {
            position: 'relative',
            zIndex: 990,
            margin: '13em auto 0em',
            maxWidth: '960px',
            overscrollBehavior: 'contain',
            paddingBottom: '300px',
        },
        headers: {
            top: 0,
            position: 'sticky',
        },
        scroller: {
            display: 'flex',
            overflowY: 'hidden',
            webkitOverflowScrolling: 'touch',
        },
        root: {
            height: '100%',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '9px 9px 0px 0px',
        },
        titleFlexBox: {
            padding: '4%',
            paddingTop: '11%',
            paddingBottom: '2%',
            backgroundColor: theme.palette.primary.main,
        },
        wrapperFlexBox: {
            backgroundColor: theme.palette.primary.main,
            height: '100%',
        },
        lineChart: {
            padding: '4%',
            paddingRight: '2.5%',
            [theme.breakpoints.up('md')]: {
                height: '400px',
                marginBottom: 100,
            },
        },
    }),
);

interface Props {
    /** Key figures from order production */
    keyFigures: IKeyfigures;

    /** List of orders with general information about each order  */
    orders: IOrder[];

    /** RouteComponentProps page allowing to check url params */
    page: IPage;

    /** List of assortment matrices from harvester */
    assortmentMatrix: IMatrixLines[];
}

/**
 * Drawer displaying project card which can be expanded when clicked, displaying further order details like day-to-day production graph, machines and assortment
 */
const StickyDrawer = (props: Props): any => {
    const classes = useStyles();

    const { keyFigures, orders, page, assortmentMatrix } = props;

    const order = orders.filter(function (order: IOrder) {
        return order.orderId === page.match.params.projectID;
    })[0];

    const orderID = order.orderId;
    const projectID = page.match.params.projectID;
    const report: IDailyReports[] = mock_daily_machine_data;

    return (
        <div className={classes.wrap}>
            <div className={classes.headers}>
                <div className={classes.scroller}></div>
            </div>
            <div className="tracks syncscroll">
                <div className="track">
                    <div className={classes.root}>
                        <div>
                            <ProjectCardDrawer order={order} orderID={orderID} />
                        </div>

                        <div className={classes.lineChart}>
                            <LineChart width={500} height={500} report={report} order={order} />
                        </div>
                        <div className={classes.titleFlexBox}>
                            <Typography variant="h3" color="textSecondary">
                                Maskiner
                            </Typography>
                        </div>
                        {projectID && <MachinesAccordion order={order} />}
                        <div className={classes.titleFlexBox}>
                            <Typography variant="h3" color="textSecondary">
                                Sortiment & Stokkmatrise
                            </Typography>
                        </div>
                        <div className={classes.wrapperFlexBox}>
                            <AssortmentComponent keyFigures={keyFigures} assortmentMatrix={assortmentMatrix} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StickyDrawer;
