import React, { Fragment } from 'react';
import { BrowserRouter, Route, Switch, RouteComponentProps } from 'react-router-dom';
import logging from './config/logging';
import routes from './config/routes';
import Navbar from './Components/Navbar';

const App: React.FunctionComponent = () => {
    logging.info('Loading webportal');

    return (
        <Fragment>
            <BrowserRouter>
                <Switch>
                    {routes.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                render={(props: RouteComponentProps<any>) => (
                                    <>
                                        {console.log(props.location.pathname)}
                                        {props.location.pathname !== '/login' && props.location.pathname !== '/' ? (
                                            <div style={{ marginBottom: 55 }}>
                                                <Navbar />
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        <route.component name={route.name} {...props} {...route.props} />
                                    </>
                                )}
                            />
                        );
                    })}
                </Switch>
            </BrowserRouter>
        </Fragment>
    );
};

export default App;
