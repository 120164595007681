import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, Fragment } from 'react';
import ProjectCard from './ProjectCard';
import { Link } from 'react-router-dom';
import IUser from '../interfaces/user';
import desktopWelcomeImg from '../images/artwork.png';
import artwork from '../images/artwork.png';
import { IOrder } from '../models/order.interface';

const useStyles = makeStyles((theme) => ({
    welcomeFlexBox: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundImage: `url(${artwork})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        paddingTop: '30%',
        color: theme.palette.common.white,
        [theme.breakpoints.up('md')]: {
            backgroundImage: `url(${desktopWelcomeImg})`,
            opacity: '70%',
        },
    },
    gradient: {
        zIndex: 1,
        left: 0,
        paddingBottom: '18%',
        height: '100%',
        width: '100%',
        background: 'linear-gradient(180deg, rgba(3, 20, 1, 0) 0%, #031401 100%)',
    },
    helloMessage: {
        paddingLeft: 20,
    },
    titleFlexBox: {
        zIndex: 3,
        padding: '4%',
        paddingTop: '12%',
        backgroundColor: theme.palette.primary.main,
        borderBottom: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.up('md')]: {
            backgroundColor: '#283430',
        },
    },
    detailsContainer: {
        width: '100vw',
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            width: '100%',
            backgroundColor: '#283430',
        },
        paddingTop: '25px',
        paddingBottom: '25px',
    },
    lastContainer: {
        width: '100vw',
        backgroundColor: theme.palette.primary.main,
        paddingBottom: '40%',
    },
    link: {
        textDecoration: 'none',
        [theme.breakpoints.up('md')]: {
            pointerEvents: 'none',
        },
    },
}));

interface Props {
    /** List of orders with general information about each order  */
    orders: IOrder[];

    /** Mock data consisting of information about the current user of the application */
    user: IUser;
}

/**
 * Component displaying the welcome message to the user, in addition to all active and inactive orders
 */
const ProjectsComponent: FC<Props> = (props: Props) => {
    const classes = useStyles();

    const { orders, user } = props;

    return (
        <Fragment>
            <div className={classes.welcomeFlexBox}>
                <div className={classes.gradient}>
                    <Typography
                        className={classes.helloMessage}
                        variant="subtitle2"
                    >{`Hei, ${user.first_name}`}</Typography>
                </div>
            </div>

            <div className={classes.titleFlexBox}>
                <Typography variant="h2" color="textSecondary">
                    Aktive prosjekter
                </Typography>
            </div>
            <div className={classes.detailsContainer}>
                {orders
                    .filter(function (order: IOrder) {
                        return order.inactive === false;
                    })
                    .map((order: IOrder, key: number) => {
                        return (
                            <Fragment key={key}>
                                <Link to={`/prosjekter/${order.orderId}`} className={classes.link}>
                                    <ProjectCard key={order.orderNum} order={order} orderID={order.orderId} />
                                </Link>
                            </Fragment>
                        );
                    })}
            </div>

            <div className={classes.titleFlexBox}>
                <Typography variant="h2" color="textSecondary">
                    Fullførte prosjekter
                </Typography>
            </div>
            <div className={classes.lastContainer}>
                {orders
                    .filter(function (order: IOrder) {
                        return order.inactive === true;
                    })
                    .map((order: IOrder, key: number) => {
                        return (
                            <Fragment key={key}>
                                <Link to={`/prosjekter/${order.orderId}`} className={classes.link}>
                                    <ProjectCard key={order.orderNum} order={order} orderID={order.orderId} />
                                </Link>
                            </Fragment>
                        );
                    })}
            </div>
        </Fragment>
    );
};

export default ProjectsComponent;
