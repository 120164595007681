import IRoute from '../interfaces/route';
import ProjectPage from '../pages/ProjectPage';
import ProjectsPage from '../pages/ProjectsPage';
import ProfilePage from '../pages/ProfilePage';
import MachinesPage from '../pages/MachinesPage';
import LoginPage from '../pages/LoginPage';

const routes: IRoute[] = [
    {
        path: '/',
        name: 'login',
        component: LoginPage,
        exact: true,
    },
    {
        path: '/prosjekter',
        name: 'projects',
        component: ProjectsPage,
        exact: true,
    },
    {
        path: '/prosjekter/:projectID',
        name: 'project',
        component: ProjectPage,
        exact: true,
    },
    {
        path: '/profil',
        name: 'profile',
        component: ProfilePage,
        exact: true,
    },

    {
        path: '/maskiner',
        name: 'maskiner',
        component: MachinesPage,
        exact: true,
    },

    {
        path: '/login',
        name: 'login',
        component: LoginPage,
        exact: true,
    },
];

export default routes;
