import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import logging from '../config/logging';
import IPage from '../interfaces/page';
import { machines } from '../mock_data/machine_info';
import MachinesComponent from '../Components/MachinesComponent';

const MachinesPage = (props: IPage) => {
    logging.info(`Loading ${props.name}`);

    return (
        <Fragment>
            <MachinesComponent machines={machines} />
        </Fragment>
    );
};

export default withRouter(MachinesPage);
